
import { defineComponent, reactive, toRefs, ref } from 'vue'
import detailLayout from '@/components/detailLayout/index.vue'
import {
  Category,
  ReqCategory,
  ReqLinkedCategory,
  LinkedCategory,
} from '@/model/competitiveAnalysis/peerData/peerData'
import { useRoute } from 'vue-router'
import accompanyApi from '@/axios/api/accompany'
import { message } from 'ant-design-vue'
import { difference } from 'lodash-es'
// import Data from '@/model/common/Data'
export default defineComponent({
  name: 'associatedPeerInformation',
  components: {
    detailLayout,
    // RightOutlined,
    // LeftOutlined,
  },

  setup() {
    //获取当前路由
    const route = useRoute()
    //获取表单组件
    const formRef = ref()
    //获取地区选择组件
    const areaSelect = ref()
    //页面数据
    const pageData = reactive({
      categoryReq: {
        currentPage: 1,
        pageSize: 10,
        dataCount: 0,
        data: { categoryName: '' },
      } as ReqCategory, //全部表格数据表单
      linkedCategoryReq: {
        currentPage: 1,
        pageSize: 10,
        dataCount: 0,
        data: { accompanyId: '' },
      } as ReqLinkedCategory, //已关联表格数据表单
      title: '物料类别与同行关联',
      buttonName: '提交',
      allCategoriesList: [] as Category[], //显示在左边的表格数据
      linkedCategoriesList: [] as LinkedCategory[],
      targetKeys: [] as string[],
      columns: [
        {
          dataIndex: 'categoryNo',
          title: '编号',
          slots: {
            customRender: 'categoryNo',
          },
          resizable: true,
        },
        {
          dataIndex: 'categoryName',
          title: '名称',
          resizable: true,
        },
        {
          dataIndex: 'edit',
          title: '操作',
          slots: {
            customRender: 'edit',
          },
          resizable: true,
        },
      ], //显示在左边的表格数据对应key
      linkedColumns: [
        {
          dataIndex: 'productCategory',
          title: '编号',
          slots: {
            customRender: 'productCategory',
          },
        },
        {
          dataIndex: 'productCategoryName',
          title: '名称',
        },
        {
          dataIndex: 'edit',
          title: '操作',
          slots: {
            customRender: 'edit',
          },
        },
      ],
      removeSelectedRowKeys: [] as string[],
      addSelectedRowKeys: [] as string[],
    })
    //获取全部数据
    const initList = () => {
      accompanyApi.CategoryPage(pageData.categoryReq).then((res) => {
        pageData.categoryReq.currentPage = res.currentPage
        pageData.categoryReq.pageSize = res.pageSize
        pageData.categoryReq.dataCount = res.dataCount
        if (res.data) {
          pageData.allCategoriesList = res.data
        } else {
          pageData.allCategoriesList = []
        }
      })
    }
    const selectList = () => {
      pageData.categoryReq.currentPage = 1
      pageData.categoryReq.pageSize = 10
      initList()
    }
    const onShowSizeChange = (page: number, pageSize: number) => {
      pageData.categoryReq.pageSize = pageSize
      initList()
    }
    const onCurrentPage = (page: number) => {
      pageData.categoryReq.currentPage = page
      initList()
    }
    selectList()
    //获取已关联物料名称同行id
    pageData.linkedCategoryReq.data.accompanyId = route.params.id as string

    //获取已关联数据
    const initLinkedList = () => {
      accompanyApi.LinkedCategoryPage(pageData.linkedCategoryReq).then((res) => {
        pageData.linkedCategoryReq.currentPage = res.currentPage
        pageData.linkedCategoryReq.pageSize = res.pageSize
        pageData.linkedCategoryReq.dataCount = res.dataCount
        if (res.data) {
          pageData.linkedCategoriesList = res.data
        } else {
          pageData.linkedCategoriesList = []
        }
      })
    }
    const selectLinkedList = () => {
      pageData.linkedCategoryReq.currentPage = 1
      pageData.linkedCategoryReq.pageSize = 10
      initLinkedList()
    }
    const onLinkedShowSizeChange = (page: number, pageSize: number) => {
      pageData.linkedCategoryReq.pageSize = pageSize
      initLinkedList()
    }
    const onLinkedCurrentPage = (page: number) => {
      pageData.linkedCategoryReq.currentPage = page
      initLinkedList()
    }
    selectLinkedList()

    const onChange = (targetKeys: string[], direction: string) => {
      console.log(targetKeys, direction)
      pageData.targetKeys = targetKeys
    }
    interface ItemSelect {
      (a: string, b: boolean): void
    }
    interface ItemSelectAll {
      (a: string[], b: boolean): void
    }
    const getRowSelection = (
      disabled: boolean,
      selectedRowKeys: string[],
      onItemSelectAll: ItemSelectAll,
      onItemSelect: ItemSelect
    ) => {
      return {
        getCheckboxProps: (item: Record<string, string | boolean>) => ({
          disabled: disabled || item.disabled,
        }),
        selectedRowKeys: selectedRowKeys,
        onSelect({ key }: Record<string, string>, selected: boolean) {
          onItemSelect(key, selected)
        },
        onSelectAll(selected: boolean, selectedRows: Record<string, string | boolean>[]) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key)
          const diffKeys: string[] = selected
            ? (difference(treeSelectedKeys, selectedRowKeys) as string[])
            : (difference(selectedRowKeys, treeSelectedKeys) as string[])
          console.log(diffKeys)

          onItemSelectAll(diffKeys, selected)
        },
      }
    }
    //搜索事件
    const onSearch = (value: string) => {
      pageData.categoryReq.data.categoryName = value
      selectList()
    }
    //搜索事件
    const onLinkedSearch = (value: string) => {
      pageData.linkedCategoryReq.data.productCategoryName = value
      selectLinkedList()
    }
    //关联事件
    const addCategoryRelatedPeers = (id: string) => {
      let data = { accompanyId: route.params.id, categoryList: [id] }
      accompanyApi
        .AddCategoryRelatedPeers(data)
        .then(() => {
          message.success('关联成功')
          pageData.addSelectedRowKeys = []
          initList()
          initLinkedList()
        })
        .catch((err) => {
          message.error(err)
        })
    }
    //移除关联事件
    const removeCategoryRelatedPeers = (id: string) => {
      let data = { accompanyId: route.params.id, categoryList: [id] }
      accompanyApi
        .RemoveCategoryRelatedPeers(data)
        .then(() => {
          message.success('移除关联成功')
          pageData.removeSelectedRowKeys = []
          initList()
          initLinkedList()
        })
        .catch((err) => {
          message.error(err)
        })
    }
    return {
      ...toRefs(pageData),
      formRef,
      // reset,
      // submit,
      areaSelect,
      onChange,
      getRowSelection,
      onSearch,
      onShowSizeChange,
      onCurrentPage,
      onLinkedShowSizeChange,
      onLinkedCurrentPage,
      onLinkedSearch,
      addCategoryRelatedPeers,
      removeCategoryRelatedPeers,
    }
  },
})
